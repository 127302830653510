import { Inject, Injectable } from '@angular/core';
import {
    ElbridgeApiResponse,
    ElbridgeCartRequest,
    ElbridgeData,
    ElbridgeInfo
} from '@obo-main/services/elbridge/elbridge.models';
import { LocalStorage } from '@obo-main/utils/localstorage.service';
import { Logger } from '@obo-main/utils/logger/logger.service';
import { Constants } from 'app/constants';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { RegionService } from '@obo-main/services/region/region.service';
import { LanguageService } from '@obo-main/services/language/language.service';
import { convertFrom } from '@obo-main/utils/convert-units';

@Injectable()
export class ElbridgeService {
    public elbridgeInfo = new BehaviorSubject<ElbridgeInfo | null>(this.validElbridgeSessionExists() ? this.getInfo() : null);

    constructor(
        @Inject('APIPREFIX') private apiPrefix: string,
        private http: HttpClient,
        @Inject(LocalStorage) private localStorage: any,
        private logger: Logger,
        private regionService: RegionService,
        private languageService: LanguageService
    ) {}

    /**
     * stores the sessionid in localestorage.
     * this enables the wholeseller mode
     * @param sessionId
     */
    public storeElbridgeInfoInCache(sessionId: string, hookUrl: string, target: string, version: string): boolean {
        const sessionInfo: ElbridgeInfo = {
            sessionId,
            hookUrl,
            target,
            version,
            timestamp: Date.now().toString()
        };
        this.logger.info(`Setting elbridge SessionId: ${sessionInfo.sessionId}, Timestamp: ${sessionInfo.timestamp}`);
        localStorage.setItem(Constants.storageTokens.elbridge_sessionInfo, JSON.stringify(sessionInfo));
        this.elbridgeInfo.next(sessionInfo);
        return true;
    }

    /**
     * This Function disables the ElbridgeMode
     */
    public removeElbridgeInfoFromCache(): void {
        this.logger.info(`Disabling Elbridge Mode`);
        localStorage.removeItem(Constants.storageTokens.elbridge_sessionInfo);
        this.elbridgeInfo.next(null);
    }

    /**
     * @returns the Elbridge Infos
     */
    public getInfo(): ElbridgeInfo {
        const sessionInfoString = this.localStorage.getItem(Constants.storageTokens.elbridge_sessionInfo);
        if (!sessionInfoString) {
            const msg = 'ElbridgeService: Elbridge not Activated. Cannot return ElbridgeInfo';
            throw new Error(msg);
        } else {
            const sessionInfo: ElbridgeInfo = JSON.parse(sessionInfoString);
            this.logger.info(
                `ElbridgeService: Elbridge Infos requested: SessionId: ${sessionInfo.sessionId}, Timestamp: ${sessionInfo.timestamp}`
            );
            return sessionInfo;
        }
    }

    /**
     * @returns true if valid Elbridge session exists
     */
    public validElbridgeSessionExists(): boolean {
        let elbridgeSessionExists = false;
        let oneDayInMs = convertFrom(1, 'd').to('ms');
        try {
            const sessionInfo = this.getInfo();
            elbridgeSessionExists =
                sessionInfo.timestamp != null && Number.parseInt(sessionInfo.timestamp) + oneDayInMs >= Date.now(); // Checks if Date in localstorage is not older than one day
        } catch (err) {
            this.removeElbridgeInfoFromCache();
        } finally {
            return elbridgeSessionExists;
        }
    }

    public getElbridgeDataForProducts(request: ElbridgeCartRequest) {
        return this.http.post<ElbridgeApiResponse>(`${this.apiPrefix}Elbridge`, request).pipe(
            map((data) => {
                return this.createElbridgeData(data);
            })
        );
    }

    public createElbridgeData(response: ElbridgeApiResponse) {
        return new ElbridgeData(
            response.hookUrl || Constants.elbridgeInterfaceAddress,
            response.version,
            response.country ? response.country : this.regionService.selectedApplicationRegion.name,
            response.language
                ? response.language
                : this.getLanguageCodeForElbridge(this.languageService.applicationLanguage.name),
            response.target,
            response.result,
            undefined
        );
    }

    //BMEcat_ETIM_ISO_6392
    //elbridge supported languages (de, en, fr)
    private getLanguageCodeForElbridge(language: string): string {
        switch (language) {
            case 'de-DE':
                return 'deu';
            case 'fr-FR':
                return 'fre';
            default:
                return 'eng';
        }
    }
}
