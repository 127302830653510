import { Injectable } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { Alert, AlertType } from '@obo-main/services/common/alert/alert.models';
import { convertFrom } from '@obo-main/utils/convert-units';

@Injectable()
export class AlertService {
    public onNewAlert: Subject<Alert> = new Subject<Alert>();
    public onAlertDispose: Subject<Alert> = new Subject<Alert>();
    public onAlertsReset: Subject<void> = new Subject<void>();

    constructor() {}

    /**
     * disposes all active Alerts
     */
    public resetAlerts() {
        this.onAlertsReset.next();
    }

    /**
     * creates a successalert
     * @param text ALert's text
     * @param disposeAfter time, after which the Alert shall disappear, default 5sec
     * @param title Alert's title
     */
    public success(text: string, disposeAfter?: number, title?: string): void {
        this.addAlert(text, AlertType.Success, disposeAfter || 5, title);
    }

    /**
     * creates a warningalert
     * @param text ALert's text
     * @param disposeAfter time, after which the Alert shall disappear, default 20sec
     * @param title Alert's title
     */
    public danger(text: string, disposeAfter?: number, title?: string): void {
        this.addAlert(text, AlertType.Error, disposeAfter || 20, title);
    }

    /**
     * creates a infoalert
     * @param text ALert's text
     * @param disposeAfter time, after which the Alert shall disappear, default 5sec
     * @param title Alert's title
     */
    public info(text: string, disposeAfter?: number, title?: string): void {
        this.addAlert(text, AlertType.Info, disposeAfter || 5, title);
    }

    public warning(text: string, disposeAfter?: number, title?: string): void {
        this.addAlert(text, AlertType.Warning, disposeAfter || 20, title);
    }

    private addAlert(text: string, type: AlertType, disposeAfter: number, title?: string): void {
        disposeAfter = convertFrom(disposeAfter, 's').to('ms');
        let newAlert: Alert = {
            text: text,
            title: title,
            type: type
        };
        this.onNewAlert.next(newAlert); // emits the new alert
        timer(disposeAfter).subscribe(() => {
            // creates a timer
            this.onAlertDispose.next(newAlert); // disposes the newly created alert after timer ellapsed
        });
    }
}
