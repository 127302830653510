<shd-sidebar>
    <ng-template shdSidebarContent>
        <div class="detail-bar webkit-scrollbar">
            <div class="detail-bar-content">
                <h2
                    class="mb-3 fw-bold"
                    [innerHTML]="'PORTAL_' + (selectedPlanning?.name | uppercase) + '_DISPLAYNAME' | translate"></h2>

                <p [innerHTML]="'PORTAL_' + (selectedPlanning?.name | uppercase) + '_DESCRIPTION' | translate"></p>
            </div>
            <div class="detail-bar-footer">
                <button class="btn btn-success planning-btn">{{ 'START_PLANNING' | translate }}</button>
            </div>
        </div>
    </ng-template>
</shd-sidebar>
