import { Component, ContentChild, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { OboMultiSelectFilter } from '@obo-common/filter/models/multiSelect';
import { FilterOptionDirective } from '@obo-common/filter/helpers/filterItemDirective.directive';
import { FilterService } from '@obo-common/filter/services/filter.service';

let selectId: number = 0;
@Component({
    selector: 'shd-multi-filter-select',
    templateUrl: './multiFilterSelect.component.html',
    exportAs: 'filterSelect'
})
export class MultiFilterSelectComponent implements OnInit {
    private tmpValue: boolean[] | undefined;
    public restoreOnClose: boolean = false;
    @Input()
    public filter: OboMultiSelectFilter<any>;
    @Input()
    public applyBtnText: string;
    @Input()
    public selectAllBtnText?: string;
    @Input()
    public unselectAllBtnText?: string;
    @Input()
    public withBorder: boolean = false;
    @Input()
    public showAllSelectButtons: boolean = true;
    @Input()
    public required: boolean;
    @Input()
    public disabled: boolean;
    @Output()
    public filterChanged: EventEmitter<any> = new EventEmitter<any>();
    public id: string = `multiSelectFilter_${selectId++}`;
    @ViewChild(NgbDropdown)
    public dropdown: NgbDropdown;
    @ContentChild(FilterOptionDirective)
    public filterOptionTpl: FilterOptionDirective;

    @HostBinding('class')
    public class = 'dropdown-filter';

    constructor(private filterService: FilterService) {}

    public handleSubmit(): void {
        this.restoreOnClose = false;
        this.dropdown.close();
        this.filter.updateValueAndValidity();
        this.filterChanged.emit();
    }

    public handleOpenChange(isOpen: boolean): void {
        if (isOpen) {
            this.restoreOnClose = true;
            this.tmpValue = this.filter.value;
        } else if (!isOpen && this.required) {
            this.checkIfValid();
        } else if (this.restoreOnClose && this.tmpValue) {
            this.filter.setValue(this.tmpValue, {
                emitEvent: false,
                onlySelf: true
            });
            this.tmpValue = undefined;
        }
    }

    public selectAll(): void {
        this.filter.setValue(
            this.filter.controls.map(() => true),
            { emitEvent: false, onlySelf: true }
        );
    }

    public unselectAll(): void {
        this.filter.setValue(
            this.filter.controls.map(() => false),
            { emitEvent: false, onlySelf: true }
        );
    }

    toggleFilter(): void {
        this.filterService.setMultiSelectFilter(this.filter);
    }

    ngOnInit(): void {
        this.filter.hasFilterTag = !this.disabled;
        if (this.filter.controls.length == 0) {
            this.disabled = true;
            this.filter.disable();
        }
        if (this.required) {
            this.filter.valueChanges.subscribe(() => {
                this.checkIfValid();
            });
        }
    }

    private checkIfValid(): void {
        if (this.filter.controls.every((x) => !x.value)) {
            this.filter.setErrors({ required: {} });
        } else {
            this.filter.setErrors(null);
        }
        this.filter.markAsTouched();
        this.filter.markAsDirty();
    }
}
