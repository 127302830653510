import { StateDeclaration, Transition, TransitionService } from '@uirouter/core';
import { TourService } from '@obo-main/services/tour/tour.service';

/**
 * checks if there is a feature set, for which a tutorial should be shown
 * @param transitionService
 */
export function tourHook(transitionService: TransitionService) {
    const tourCriteria = {
        to: (state: any) => true
    };

    const action = (transition: Transition, state: StateDeclaration) => {
        const tourService = transition.injector().get(TourService) as TourService;
        tourService.tourChange.next(undefined);
        if (state.data.tour?.tourId) {
            tourService.tourId.set(state.data.tour.tourId);
        }
    };
    // add the hook to transitionsservice
    transitionService.onEnter(tourCriteria, action, { priority: 50 });
}
