import { InAppNavigationService } from '@obo-main/services/inAppNavigation/inAppNavigation.service';
import { StateDeclaration, Transition, TransitionService } from '@uirouter/core';

export function inAppNavigationHook(transitionService: TransitionService) {
    const inAppNavigationCriteria = {
        to: (state: any) => true
    };

    const action = (transition: Transition, state: StateDeclaration) => {
        const inAppNavigationService = transition.injector().get(InAppNavigationService) as InAppNavigationService;
        if (state.data?.showInAppNavigation) {
            inAppNavigationService.isActive.next(true);
        } else {
            inAppNavigationService.isActive.next(false);
        }
        inAppNavigationService.saveButtonTemplate.next(undefined);
        inAppNavigationService.additionalButtonTemplate.next(undefined);
    };

    transitionService.onEnter(inAppNavigationCriteria, action, {
        priority: 55
    });
}
