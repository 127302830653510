import { Constants } from 'app/constants';

export class AdminNavElement {
    public name: string;
    public key: string; // translatekey
    public uiSref: string;
    public roles?: Constants.Role[];
    public description: string;
    public icon?: string[];
}

export class Culture {
    id: number;
    name: string;
    displayName: string;
    modificationDate: Date | string;
    modules: { isActive: boolean; name: string; id: number }[];
}

export class InterceptUrl {
    constructor() {
        this.Create = new InterceptUrlType();
        this.Destroy = new InterceptUrlType();
        this.Read = new InterceptUrlType();
        this.Update = new InterceptUrlType();
    }
    [key: string]: InterceptUrlType;
    public Create: InterceptUrlType;
    public Update: InterceptUrlType;
    public Destroy: InterceptUrlType;
    public Read: InterceptUrlType;
}

export class InterceptUrlType {
    public replace?: string;
    public append?: string;
}

export class EditServiceOptions {
    constructor() {
        this.interceptUrl = new InterceptUrl();
    }
    public defaultRoute: string;
    public interceptUrl: InterceptUrl;
}

export class ExcelExportResponse {
    public blob: any;
    public name: string;
}

export class DataImportLogItem {
    public startDate: Date;
    public endDate: Date;
    public duration: number;
    public fileName: string;
}

export class ProductModel {
    id: number;
    itemNumber: number;
    name: string;
    labelOne: string;
    labelTwo: string;
    modificationDate: string;
    isActive: boolean;
    values: ProductValueModel[];
    regions: ProductRegionModel[];
}

export class ProductValueModel {
    id: string;
    attributeId: number;
    attributeName: string;
    valueId: number | null;
    value: string;
}

export class ProductRegionModel {
    name: string;
    price: number;
    currency: string;
    currencySymbol: string;
    isPriceUsed: boolean;
    isPriceVisible: boolean;
    isReleased: boolean;
}

export enum CartType {
    Elbridge = 1,
    Export = 2
}
