import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GuidedTour, Orientation } from '@obo-libraries/ngx-guided-tour/guided-tour.constants';
@Injectable()
export class SharedGuidedTours {
    constructor(private translateService: TranslateService) {}

    /**
     *
     * @param stepsToSkip index of the steps to skip
     */
    public productSelectorTour(stepsToSkip?: number[]): GuidedTour {
        const tour = {
            steps: [
                {
                    title: this.translateService.instant('PRODUCT_SELECTOR_TOUR_STEP_1_TITLE'),
                    content: this.translateService.instant('PRODUCT_SELECTOR_TOUR_STEP_1_CONTENT')
                },
                {
                    title: this.translateService.instant('PRODUCT_SELECTOR_TOUR_STEP_2_TITLE'),
                    content: this.translateService.instant('PRODUCT_SELECTOR_TOUR_STEP_2_CONTENT'),
                    selector: '.product-card',
                    orientation: Orientation.Right
                },
                {
                    title: this.translateService.instant('PRODUCT_SELECTOR_TOUR_STEP_3_TITLE'),
                    content: this.translateService.instant('PRODUCT_SELECTOR_TOUR_STEP_3_CONTENT'),
                    selector: '.product-card .btn-more-details',
                    orientation: Orientation.Right
                },
                {
                    title: this.translateService.instant('PRODUCT_SELECTOR_TOUR_STEP_4_TITLE'),
                    content: this.translateService.instant('PRODUCT_SELECTOR_TOUR_STEP_4_CONTENT'),
                    selector: '.filters',
                    orientation: Orientation.Bottom
                }
            ]
        };
        if (stepsToSkip && stepsToSkip.length > 0) {
            tour.steps = tour.steps.filter((_, index) => !stepsToSkip.includes(index));
        }
        return tour;
    }

    /**
     *
     * @param stepsToSkip index of the steps to skip
     */
    public finishWizardTour(stepsToSkip?: number[]): GuidedTour {
        const tour = {
            steps: [
                {
                    title: this.translateService.instant('FINISH_WIZARD_TOUR_STEP_1_TITLE'),
                    content: this.translateService.instant('FINISH_WIZARD_TOUR_STEP_1_CONTENT')
                },
                {
                    title: this.translateService.instant('FINISH_WIZARD_TOUR_STEP_2_TITLE'),
                    content: this.translateService.instant('FINISH_WIZARD_TOUR_STEP_2_CONTENT'),
                    selector: '.img-fluid',
                    orientation: Orientation.Right
                },
                {
                    title: this.translateService.instant('FINISH_WIZARD_TOUR_STEP_3_TITLE'),
                    content: this.translateService.instant('FINISH_WIZARD_TOUR_STEP_3_CONTENT'),
                    selector: '.product-table',
                    orientation: Orientation.Left
                },
                {
                    title: this.translateService.instant('FINISH_WIZARD_TOUR_STEP_4_TITLE'),
                    content: this.translateService.instant('FINISH_WIZARD_TOUR_STEP_4_CONTENT'),
                    selector: '#finishWizardForm',
                    orientation: Orientation.Right
                },
                {
                    title: this.translateService.instant('FINISH_WIZARD_TOUR_STEP_5_TITLE'),
                    content: this.translateService.instant('FINISH_WIZARD_TOUR_STEP_5_CONTENT'),
                    selector: '#in-app-navigation .btn-success',
                    orientation: Orientation.Top
                },
                {
                    title: this.translateService.instant('FINISH_WIZARD_TOUR_STEP_6_TITLE'),
                    content: this.translateService.instant('FINISH_WIZARD_TOUR_STEP_6_CONTENT'),
                    selector: '#in-app-navigation .btn-secondary',
                    orientation: Orientation.Top
                }
            ]
        };
        if (stepsToSkip && stepsToSkip.length > 0) {
            tour.steps = tour.steps.filter((_, index) => !stepsToSkip.includes(index));
        }
        return tour;
    }
}
