/**
 *  Icon imports below this comment!
 *  We need to import all icons individually to reduce bundle size. In comparison to FontAwesome 4.7 we save about 250kb of Font icons we did not use!
 * */
import { IconDefinition, IconPack } from '@fortawesome/fontawesome-common-types';

import { faAppStoreIos, faGooglePlay, faWindows, faYoutube } from '@fortawesome/free-brands-svg-icons';

import {
    faAnalytics,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowDown,
    faArrowDownLeft,
    faArrowDownRight,
    faArrowLeft,
    faArrowRight,
    faArrowToBottom,
    faArrowToRight,
    faArrowTurnLeft,
    faArrowUp,
    faArrowUpLeft,
    faArrowUpRight,
    faArrowUpRightFromSquare,
    faArrowsAlt,
    faArrowsLeftRight,
    faArrowsLeftRightToLine,
    faBars,
    faBarsStaggered,
    faBinary,
    faBlockBrickFire,
    faBorderBottom,
    faBoreHole,
    faBroom,
    faBuildingMagnifyingGlass,
    faBullhorn,
    faCalculator,
    faCartPlus,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronDoubleDown,
    faChevronDoubleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChimney,
    faCircle,
    faCircleXmark,
    faClipboard,
    faClipboardCheck,
    faClipboardListCheck,
    faClock,
    faCogs,
    faCommentAltLines,
    faCompressWide,
    faConstruction,
    faCopy,
    faCube,
    faDatabase,
    faDiagramProject,
    faDistributeSpacingHorizontal,
    faDownload,
    faEllipsisV,
    faEmptySet,
    faExchange,
    faExclamationTriangle,
    faExpandWide,
    faEye,
    faEyeSlash,
    faFile,
    faFileArchive,
    faFileExcel,
    faFileExport,
    faFilePdf,
    faFileSignature,
    faFileWord,
    faFilter,
    faFilterSlash,
    faFlag,
    faFolder,
    faFrame,
    faGlobeAmericas,
    faGlobeEurope,
    faGrid,
    faHorizontalRule,
    faHouseBuilding,
    faHouseChimney,
    faIdCard,
    faImages,
    faInfoCircle,
    faInfoSquare,
    faExclamationCircle as faLExclamationCircle,
    faLanguage,
    faList,
    faListAlt,
    faListCheck,
    faListTree,
    faLocationCrosshairs,
    faLocationDot,
    faMagnifyingGlass,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
    faMapLocation,
    faMaximize,
    faMinus,
    faNewspaper,
    faOutlet,
    faPaintRoller,
    faPenRuler,
    faPencil,
    faPhotoFilmMusic,
    faPlay,
    faPlug,
    faPlus,
    faPlusCircle,
    faPrint,
    faQuestion,
    faRectangleWide,
    faRoute,
    faScaleUnbalanced,
    faScrewdriverWrench,
    faSearch,
    faShield,
    faShieldAlt,
    faShoppingCart,
    faSignIn,
    faSignOut,
    faSitemap,
    faSlidersH,
    faSlidersUp,
    faSnowflake,
    faSolarPanel,
    faSpinner,
    faSpinnerThird,
    faSquare,
    faSquareDashed,
    faStar,
    faSunCloud,
    faSync,
    faTableCellsLarge,
    faTh,
    faThLarge,
    faThumbTack,
    faTimer,
    faTimes,
    faTimesCircle,
    faTint,
    faTrashAlt,
    faTruck,
    faTruckLoading,
    faUndo,
    faUpload,
    faUser,
    faUserCircle,
    faUserCog,
    faUserHardHat,
    faUserHeadset,
    faUserPlus,
    faUserSecret,
    faUsers,
    faWeightHanging,
    faWind,
    faWindowRestore,
    faWrench,
    faXmark,
    faSave as falSave,
    faFire,
    faPersonRunning,
    faSolarSystem,
    faArrowsFromLine
} from '@fortawesome/pro-light-svg-icons';

import {
    faArrowDown as faArrowDownSolid,
    faArrowUp as faArrowUpSolid,
    faCamera,
    faCheckCircle as faCheckCircleSolid,
    faCircleCheck,
    faCircleNotch,
    faClipboardList,
    faDiagramProject as faDiagramProjectSolid,
    faEnvelope,
    faExclamationCircle,
    faFileSignature as faFileSignatureSolid,
    faFilter as faFilterSolid,
    faGlobe,
    faInfoCircle as faInfoCircleSolid,
    faInfo as faInfoSolid,
    faLock,
    faLockOpen,
    faPlusCircle as faPlusCircleSolid,
    faQuestion as faQuestionSolid,
    faSave,
    faThumbTack as faThumbTackSolid,
    faUserCheck
} from '@fortawesome/free-solid-svg-icons';

import {
    faCameraRetro,
    faCircleEllipsis,
    faExternalLink,
    faFileUser,
    faHome,
    faLeft,
    faLeftToLine,
    faRight,
    faRightToLine
} from '@fortawesome/pro-solid-svg-icons';

import { fak } from '@awesome.me/kit-efa8525a58/icons';

export const faImports: IconDefinition[] = [
    faUsers,
    faUser,
    faUpload,
    faDownload,
    faTimes,
    faSync,
    faTruck,
    faTint,
    faSearch,
    faList,
    faListAlt,
    faCamera,
    faLanguage,
    faShieldAlt,
    faCogs,
    faNewspaper,
    faQuestion,
    faTruckLoading,
    faStar,
    faCalculator,
    faEllipsisV,
    faFilePdf,
    faArrowUp,
    faCheckCircleSolid,
    faCheckCircle,
    faSitemap,
    faClock,
    faLock,
    faLockOpen,
    faWrench,
    faClock,
    faPaintRoller,
    faBroom,
    faArrowRight,
    faTh,
    faDatabase,
    faPlus,
    faPlusCircle,
    faGlobeAmericas,
    faCommentAltLines,
    faTrashAlt,
    faPencil,
    faCameraRetro,
    faCopy,
    faCube,
    faWeightHanging,
    faCircle,
    faCheckSquare,
    faSquare,
    faChevronDoubleUp,
    faChevronDoubleDown,
    faAngleRight,
    faAngleLeft,
    faSignIn,
    faSignOut,
    faAppStoreIos,
    faGooglePlay,
    faYoutube,
    faSpinner,
    faWindows,
    faGlobe,
    faGlobeEurope,
    faUserHeadset,
    faInfoSquare,
    faShield,
    faUserCog,
    faConstruction,
    faWindowRestore,
    faUserCircle,
    faThLarge,
    faList,
    faPlug,
    faSlidersH,
    faInfoCircle,
    faBullhorn,
    faIdCard,
    faUserSecret,
    faCheck,
    faFileSignature,
    faEnvelope,
    faExternalLink,
    faUserCheck,
    faClipboardList,
    faSave,
    faFileUser,
    faAnalytics,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faAngleDown,
    faAngleUp,
    faArrowsAlt,
    faUndo,
    faBars,
    faFileArchive,
    faMinus,
    faArrowToBottom,
    faExclamationCircle,
    faUndo,
    faShoppingCart,
    faTimesCircle,
    faExpandWide,
    faCompressWide,
    faExchange,
    faLExclamationCircle,
    faCircleNotch,
    faClipboard,
    faClipboardCheck,
    faClipboardListCheck,
    faQuestionSolid,
    faImages,
    faEmptySet,
    faHorizontalRule,
    faFileExcel,
    faFile,
    falSave,
    faPrint,
    faFileWord,
    faInfoCircleSolid,
    faFilter,
    faEye,
    faEyeSlash,
    faHome,
    faHouseBuilding,
    faPenRuler,
    faFolder,
    faUserHardHat,
    faSpinnerThird,
    faFilter,
    faFilterSlash,
    faDiagramProject,
    faDiagramProjectSolid,
    faBarsStaggered,
    faInfoSolid,
    faSolarPanel,
    faBinary,
    faPhotoFilmMusic,
    faCircleCheck,
    faArrowUpRightFromSquare,
    faFileExport,
    faPlusCircleSolid,
    faOutlet,
    faTimer,
    faScrewdriverWrench,
    faMagnifyingGlass,
    faArrowLeft,
    faPlay,
    faLocationDot,
    faMapLocation,
    faSnowflake,
    faWind,
    faLocationCrosshairs,
    faRectangleWide,
    faMaximize,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faExclamationTriangle,
    faChimney,
    faGrid,
    faListTree,
    faArrowsLeftRight,
    faArrowDown,
    faFrame,
    faTableCellsLarge,
    faArrowsLeftRightToLine,
    faArrowToRight,
    faArrowDownRight,
    faArrowDownLeft,
    faArrowUpRight,
    faArrowUpLeft,
    faFileSignature,
    faFileSignatureSolid,
    faThumbTackSolid,
    faThumbTack,
    faSunCloud,
    faHouseChimney,
    faCartPlus,
    faRoute,
    faBorderBottom,
    faArrowTurnLeft,
    faSlidersUp,
    faSquareDashed,
    faBlockBrickFire,
    faBoreHole,
    faBuildingMagnifyingGlass,
    faCircleEllipsis,
    faDistributeSpacingHorizontal,
    faLeftToLine,
    faLeft,
    faRightToLine,
    faRight,
    faFilterSolid,
    faArrowUpSolid,
    faArrowDownSolid,
    faListCheck,
    faFlag,
    faCalculator,
    faCircleXmark,
    faXmark,
    faUserPlus,
    faScaleUnbalanced,
    faFire,
    faPersonRunning,
    faSolarPanel,
    faSolarSystem,
    faArrowsFromLine
];

export const faKit: IconPack[] = [fak];
