import { Inject, Injectable, signal } from '@angular/core';
import { GuidedTour } from '@obo-libraries/ngx-guided-tour/guided-tour.constants';
import { GuidedTourService } from '@obo-libraries/ngx-guided-tour/guided-tour.service';
import { LocalStorage } from '@obo-main/utils/localstorage.service';
import { Subject } from 'rxjs';

@Injectable()
export class TourService {
    private _currentTour: GuidedTour | undefined;
    private isEnabled: boolean = false;

    public tourChange: Subject<TourEvent> = new Subject<TourEvent>();
    public tourId = signal(undefined);

    constructor(@Inject(LocalStorage) private localStorage: Storage, private guidedTourService: GuidedTourService) {}

    public toggleService(isEnabled: boolean): void {
        this.isEnabled = isEnabled;
    }

    public init(guidedTour: GuidedTour): void {
        if (this.isEnabled) {
            guidedTour.tourId = this.tourId();
            this._currentTour = guidedTour;
            if (!this.localStorage.getItem(guidedTour.tourId) && guidedTour.tourId) {
                this.start(guidedTour);
                this.emitEvent({ tour: this._currentTour, enabled: true });
            } else {
                this.emitEvent({ tour: undefined, enabled: true });
            }
            this.tourId.set(undefined);
        }
    }

    public restartTour(): void {
        if (this._currentTour) {
            this.localStorage.removeItem(this._currentTour.tourId);
            this.start(this._currentTour);
        }
    }

    private start(guidedTour: GuidedTour): void {
        setTimeout(() => {
            this.guidedTourService.startTour(guidedTour);
            this.guidedTourService.guidedTourCurrentStepStream.subscribe((step) => {
                if (!step) {
                    this.localStorage.setItem(guidedTour.tourId, 'true');
                    this.emitEvent({ tour: undefined, enabled: true });
                }
            });
        });
    }

    private emitEvent(tourEvent: TourEvent): void {
        this.tourChange.next(tourEvent);
    }
}

export class TourEvent {
    tour: GuidedTour | undefined;
    enabled: boolean;
}
