import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginData } from '@obo-account/models/account.models';
import { RequestPasswordService } from '@obo-account/requestPassword/requestPassword.service';
import { AuthService } from '@obo-main/services/auth/auth.service';
import { AlertService } from '@obo-main/services/common/alert/alert.service';
import { SpinnerService } from '@obo-main/services/common/spinner/spinner.service';
import { SEOService } from '@obo-main/services/seo/seo.service';
import { StateService } from '@uirouter/core';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @Input()
    public redirectState?: string;
    @Input()
    public redirectParams: any;
    @Input()
    public email: string;

    @Input()
    public passwordPattern: string;
    public user: LoginData = new LoginData();
    public showRequestActivationMailInfo: boolean = false;
    constructor(
        private seo: SEOService,
        private translateService: TranslateService,
        private authService: AuthService,
        private spinnerService: SpinnerService,
        private alertSerice: AlertService,
        private stateService: StateService,
        private requestPasswordService: RequestPasswordService
    ) {}

    public ngOnInit(): void {
        this.seo.updateTitle(this.translateService.instant('LOGIN_META_TITLE'));
        this.user.username = this.email;
    }

    public login() {
        this.spinnerService.startSpinner();
        this.authService
            .login(this.user.username, this.user.password)
            .pipe(finalize(() => this.spinnerService.stopSpinner()))
            .subscribe({
                next: () => {
                    this.spinnerService.stopSpinner();
                    if (this.redirectState) {
                        this.stateService.go(this.redirectState, this.redirectParams);
                    } else {
                        this.stateService.go('login');
                    }
                },
                error: (err) => {
                    if (err.error === 'interaction_required') {
                        this.showRequestActivationMailInfo = true;
                    }
                    this.alertSerice.danger(this.translateService.instant('LOGINCTRL_LOGINFAILURE'));
                }
            });
    }

    public requestConfirmationMail(): void {
        this.spinnerService.startSpinner(this.translateService.instant('LOADING'));
        this.requestPasswordService.requestActivationMail(this.user.username).subscribe({
            next: () => {
                this.alertSerice.success(this.translateService.instant('LOGINCTRL_RECEIVE_CONFIRMATION_EMAIL_AGAIN'));
                this.spinnerService.stopSpinner();
            },
            error: () => {
                this.alertSerice.danger(this.translateService.instant('LOGINCTRL_FORGOTCONFIRMFAILURE'));
                this.spinnerService.stopSpinner();
            }
        });
    }
}
