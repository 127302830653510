import configureMeasurements from 'convert-units';
import allMeasures from 'convert-units/definitions/all';

export type Unit = LengthUnit | TimeUnit | PercentageUnit | AreaUnit | VolumeUnit | PowerUnit | LoadUnit | ForceUnit | TorqueUnit;
type LengthUnit = 'mm' | 'cm' | 'm';
type TimeUnit = 'ms' | 's' | 'd';
type PercentageUnit = '%' | 'dec';
type AreaUnit = 'mm2' | 'cm2' | 'm2';
type VolumeUnit = 'mm3' | 'cm3' | 'l';
type PowerUnit = 'W' | 'kW';
type LoadUnit = 'kN/m' | 'N/m' | 'MN/m';
type ForceUnit = 'N' | 'kN';
type TorqueUnit = 'kNm' | 'Ncm';

const customMeasure: any = {
    percent: {
        systems: {
            PERCENT: {
                '%': {
                    name: {
                        singular: 'percent',
                        plural: 'percents'
                    },
                    to_anchor: 1
                },
                dec: {
                    name: {
                        singular: 'decimal',
                        plural: 'decimals'
                    },
                    to_anchor: 100
                }
            }
        },
        anchors: {
            PERCENT: {
                PERCENT: {
                    ratio: 1
                }
            }
        }
    },
    load: {
        systems: {
            LOAD: {
                'MN/m': {
                    name: {
                        singular: 'megaNewtonPerMeter',
                        plural: 'megaNewtonPerMeter'
                    },
                    to_anchor: 1000000
                },
                'kN/m': {
                    name: {
                        singular: 'kiloNewtonPerMeter',
                        plural: 'kiloNewtonsPerMeter'
                    },
                    to_anchor: 1000
                },
                'N/m': {
                    name: {
                        singular: 'newtonPerMeter',
                        plural: 'newtonsPerMeter'
                    },
                    to_anchor: 1
                }
            }
        },
        anchors: {
            LOAD: {
                LOAD: {
                    ratio: 1
                }
            }
        }
    },
    force: {
        systems: {
            FORCE: {
                kN: {
                    name: {
                        singular: 'kiloNewton',
                        plural: 'kiloNewtons'
                    },
                    to_anchor: 1000
                },
                N: {
                    name: {
                        singular: 'newton',
                        plural: 'newtons'
                    },
                    to_anchor: 1
                }
            }
        },
        anchors: {
            LOAD: {
                LOAD: {
                    ratio: 1
                }
            }
        }
    },
    torque: {
        systems: {
            TORQUE: {
                Ncm: {
                    name: {
                        singular: 'newtonCentimeter',
                        plural: 'newtonCentimeters'
                    },
                    to_anchor: 1
                },
                kNm: {
                    name: {
                        singular: 'kiloNewtonMeter',
                        plural: 'kiloNewtonMeters'
                    },
                    to_anchor: 100000
                }
            }
        },
        anchors: {
            LOAD: {
                LOAD: {
                    ratio: 1
                }
            }
        }
    }
};

const combinedMeasures = { ...allMeasures, ...customMeasure };
const convertConfig = configureMeasurements(combinedMeasures);

/**
 *
 * @param {any} value The value to be converted
 * @param {string} fromUnit The unit of the input value
 * @param {string} toUnit The unit to convert the value to
 */

export function convertFrom(value: any, fromUnit: Unit) {
    return {
        to: (toUnit: Unit) => convertConfig(parseFloat(value)).from(fromUnit).to(toUnit)
    };
}
